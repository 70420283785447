import { mapGetters } from 'vuex';

export default {
  name: 'TmplateFooter',
  computed: {
    ...mapGetters({
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerId: 'GET_MARKETER_ID',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      destinationList: 'GET_DOMESTIC_DESTINATION',
      chainList: 'GET_HOTEL_CHAIN_TABLE',
      staticPageList: 'GET_FOOTER_PAGES_LIST',
      staticPageSubtypeList: 'GET_FOOTER_CATEGORY_LIST',
      isEmalonMode: 'GET_IS_EMALON_MODE',
      isAgentSite: 'GET_ODYSSEY_AGENT_STATE',
    }),
    customFooterSubjects() {
      return this.marketerAgencyContent?.marketerSpecificContents?.footerItems ?? [];
    },
    getChainList() {
      if (!this.chainList) return null;
      const { chainList } = this;
      const list = [];
      chainList.forEach((item) => {
        const subItem = [];
        subItem[0] = item.id;
        subItem[1] = item.name;
        list.push(subItem);
      });
      return list;
    },
    getDestinationList() {
      if (!this.destinationList) return null;
      const list = [];
      this.destinationList.forEach((item) => {
        const subItem = [];
        subItem[0] = item.code;
        subItem[1] = item.name;
        list.push(subItem);
      });
      return list;
    },
    getServiceList() {
      const packSiteDomain = 'https://www.flying.co.il';
      const list = [
        [`${packSiteDomain}/אודות_`, this.$t('footer.footer-list.about')],
        [`${packSiteDomain}/צור_קשר`, this.$t('footer.footer-list.contact-us')],
        // ['baraka@flying.co.il', this.$t('footer.footer-list.contact-us')],
        [`${packSiteDomain}/מדיניות_פרטיות`, this.$t('footer.footer-list.to-israel')],
        [`${packSiteDomain}/מידע_לנוסע`, this.$t('footer.footer-list.passenger-information')],
        [`${packSiteDomain}/תקנון_ביטול_וזיכוי`, this.$t('footer.footer-list.cancel-and-credit')],
        [`${packSiteDomain}/תנאים_כללים_והגבלת_אחריות`, this.$t('footer.footer-list.general-conditions-and-limitation-of-liability')],
        [`${packSiteDomain}/תקנון_מועדון_לקוחות_האתר`, this.$t('footer.footer-list.site-customer-club-rules')],
        [`${packSiteDomain}/מדריך_היעדים`, this.$t('footer.footer-list.destination-guide')],
        [`${packSiteDomain}/בקשה_לביטול_הזמנה`, this.$t('footer.footer-list.request-to-cancel-an-order')],
        [`${packSiteDomain}/הרשמה_לרשימת_תפוצה`, this.$t('footer.footer-list.Subscribe-to-a-mailing-list')],
      ];

      if (this.isEmalonMode) {
        list.unshift(['%D7%94%D7%A1%D7%93%D7%A8%D7%99_%D7%A0%D7%92%D7%99%D7%A9%D7%95%D7%AA', this.$t('footer.footer-list.accessibility-statement')]);
      }
      return list;
    },
    getStatictPageSubtypes() {
      const list = [];
      if (this.staticPageSubtypeList) {
        this.staticPageSubtypeList.forEach((item) => {
          const subItem = [];
          subItem[0] = item.code;
          subItem[1] = item.nameTranslationEng;
          subItem[2] = item.nameTranslationHeb;
          list.push(subItem);
        });
      }
      return list;
    },
    getStaticPages() {
      const list = [];
      if (this.staticPageList) {
        Object.entries(this.staticPageList).forEach((item) => {
          const subItem = [];
          subItem[0] = item[1].link || item[1].slug;
          subItem[1] = item[1].title;
          subItem[2] = item[1].category;
          list.push(subItem);
        });
      }
      return list;
    },
    hostData() {
      let currHostData = null;
      if (this.marketerId !== '') {
        currHostData = this.marketerAgencyContent;
      } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
        currHostData = this.whiteLabel;
      }
      return currHostData;
    },
    showFacebookLink() {
      return this.marketerId === '' || !!this.hostData?.facebookLink;
    },
    facebookLink() {
      const defaultLink = 'https://he-il.facebook.com/flying.co.il/';
      const link = this.hostData?.facebookLink ? `http://www.facebook.com/${this.hostData?.facebookLink.replace('http://www.facebook.com/', '')}` : defaultLink;
      return link;
    },
    showInstagramLink() {
      return this.marketerId === '' || !!this.hostData?.instagramLink;
    },
    instagramLink() {
      const defaultLink = 'https://www.instagram.com/flyingcarpet_il/';
      const link = this.hostData?.instagramLink ? `https://www.instagram.com/${this.hostData?.instagramLink.replace('https://www.instagram.com/', '')}` : defaultLink;
      return link;
    },
    showTwitterLink() {
      return this.marketerId === '' || !!this.hostData?.twitterLink;
    },
    twitterLink() {
      const defaultLink = 'https://twitter.com/@flyingcarpetltd';
      const link = this.hostData?.twitterLink ? `https://twitter.com/${this.hostData?.twitterLink.replace('https://twitter.com/', '')}` : defaultLink;
      return link;
    },
    showMainFooter() {
      const footerVisibility = this.marketerAgencyContent?.marketerSpecificContents?.footerVisibility ?? 1;
      const show = this.marketerId === '' || (this.marketerId !== '' && footerVisibility === 1);
      return show;
    },
    showCustomSubjectFooter() {
      const footerVisibility = this.marketerAgencyContent?.marketerSpecificContents?.footerVisibility ?? 1;
      const show = this.marketerId !== '' && footerVisibility === 2;
      return show;
    },
    srcLogo() {
      const { marketerAgencyContent } = this;
      const logoUrls = (this.marketerAgencyContent === null || this.marketerAgencyContent === undefined) ? this.whiteLabel?.logoUrls : marketerAgencyContent?.logoUrls;

      // check domain contains 'emalon' phrase
      const isEmalonAgent = window.location.href.includes('emalon');
      return this.isAgentSite && isEmalonAgent ? logoUrls?.[1] : logoUrls?.[0];
    },
  },
  data() {
    return {
      landingQuery: '',
    };
  },
  mounted() {
    this.landingQuery = window.localStorage.getItem('landing-url') ? `${window.location.origin}/${window.localStorage.getItem('landing-url')}` : '';
  },
  methods: {
  },
};
