<template>
  <footer class="new_footer_area">
    <div class="footer_body" v-if="device === 'desktop'">
      <div class="container">
        <div class="row d-flex justify-content-around" v-if="showMainFooter">
          <footer-column
            :itemList="getChainList"
            :path="`chain`"
            :caption="$t('footer.footer-list.hotel-chain')"
            :show="showMainFooter"
          />
          <footer-column
            :itemList="getDestinationList"
            :path="`city`"
            :caption="$t('footer.footer-list.destinations')"
            :index="`dest`"
            :show="showMainFooter"
          />
          <footer-column
            :itemList="getServiceList"
            :path="``"
            :caption="$t('footer.footer-list.customer-service')"
            :index="`service`"
            :show="showMainFooter"
          />
          <footer-tree-column v-if="!isEmalonMode"
            :itemList="getStaticPages"
            :subTypeList="getStatictPageSubtypes"
            :caption="$t('footer.footer-list.goals-guide')"
            :show="showMainFooter"
          />
        </div>
        <div class="row d-flex justify-content-start" v-if="showCustomSubjectFooter">
          <custom-footer-column v-for="(subject, i) in customFooterSubjects" :key="i"
            :subject="subject"
            :show="showCustomSubjectFooter"
          />
        </div>
      </div>
    </div>

    <div class="footer_body" v-if="device === 'mobile'">
      <div class="container">
        <footer-column-mobile
          :itemList="getChainList"
          :path="`chain`"
          :caption="$t('footer.footer-list.hotel-chain')"
          :index="`chain`"
          :show="showMainFooter"
        />
        <footer-column-mobile
          :itemList="getDestinationList"
          :path="`city`"
          :caption="$t('footer.footer-list.destinations')"
          :index="`dest`"
          :show="showMainFooter"
        />
        <footer-column-mobile
          :itemList="getServiceList"
          :path="``"
          :caption="$t('footer.footer-list.customer-service')"
          :index="`service`"
          :show="showMainFooter"
        />
        <footer-tree-column-mobile v-if="!isEmalonMode"
          :itemList="getStaticPages"
          :subTypeList="getStatictPageSubtypes"
          :caption="$t('footer.footer-list.goals-guide')"
          :show="showMainFooter"
        />
        <custom-footer-column-mobile v-for="(subject, i) in customFooterSubjects" :key="i"
            :subject="subject"
            :index="i"
            :show="showCustomSubjectFooter"
        />
      </div>
    </div>

    <div class="footer_body" style="direction: rtl;" v-if="showMainFooter">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12 col-md-3">
            <div class="footer">
              <div class="footer_logo d-flex"><img :src="srcLogo" alt="logo" title="" class="m-auto"/></div>
              <h5>{{$t("footer.follow-us-on")}}</h5>
              <ul class="footer_social">
                <li v-if="showTwitterLink"><a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a></li>
                <li v-if="showFacebookLink"><a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-google"></i></a></li>
                <li v-if="showInstagramLink"><a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a></li>
                <!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
              </ul>
            </div>
          </div>
          <!-- <div class="col-6 col-sm-12 col-md-9">
            <div class="footer row">
              <div class="col-12 col-sm-6 col-md-6 text-center show-message form-group">{{$t("footer.member-login-alert")}}</div>
              <div class="col-12 col-sm-6 col-md-3 text-center">
                <member-register/>
              </div>
              <div class="col-12 col-sm-6 col-md-2 text-center"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="copyright_area"><p>{{ $t("footer.all-reserved-title", {year: new Date().getFullYear(), agencyName: whiteLabel.name}) }}</p></div>

  </footer>
</template>

<script>
import TemplateFooter from './TemplateFooter';

export default {
  name: 'Footer',
  mixins: [TemplateFooter],
  components: {
    FooterColumn: () => import('../footerColumn/FooterColumnTheme0'),
    CustomFooterColumn: () => import('../customFooterColumn/CustomFooterColumnTheme0'),
    FooterTreeColumn: () => import('../FooterTreeColumn'),
    FooterColumnMobile: () => import('../FooterColumnMobile'),
    CustomFooterColumnMobile: () => import('../customFooterColumn/CustomFooterColumnMobileTheme0'),
    FooterTreeColumnMobile: () => import('../footerTreeColumnMobile/FooterTreeColumnMobileTheme0'),
  },
};
</script>

<style>
  body.flat-404 footer{
    margin-bottom: 0;
    color: white;
  }
  body.flat-404 .footer-btm .copy ul li a {
    color: white;
  }
  .row_title {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
  }
</style>

<style scoped>
  .show-message{
    font-size: 1.5rem;
    color: white;
  }
  .link-nDetail .short-links .list-group-item{
    padding: 0;
  }
  footer .list-group,
  .link-nDetail .footer-social ul {
    padding: 0;
  }
  footer .shortLink-wrapper:not(:last-child){
    border-left: 1px solid white;
  }
  footer .shortLink-wrapper{
    padding: 0 1.5rem;
  }
  .new_footer_area{
    background-image: url('/assets/img/newfooterbg.jpg');
  }
  .footer_body .footer .footer_logo img{
    /* height: 100px;
    width: auto; */
  }
</style>
